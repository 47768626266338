import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {DetailRowIconRender} from "../../../common/components/agGrid/DetailRowIconRender";
import {AgGridReact} from "ag-grid-react";
import {getAgGridDefault, rightAligned} from "../../../common/components/agGrid/agGridUtility";
import AgGridContainer from "../../../common/layout/AgGridContainer";
import useGridFilter from "../../../common/hooks/useGridFilter";
import useApi from "../../../api/useApi";
import {currencyFormatter} from "../../../common/formatters/useLocaleFormatters";
import FlujoAutorizacionDetalle from "./FlujoAutorizacionDetalle";
import Page from "../../../common/layout/Page";
import GridAddButtonComponent from "./GridAddButtonComponent";

const FlujoAutorizaciones = () => {
    const {t} = useTranslation();
    const editable = true// isEditable(menuOptions.usuarios);
    const api = useApi('catalogos/flujoAutorizacion');
    const {data, onFilter, addEditGridContext, editForm} = useGridFilter({api});
    const [columnDefs] = useState([
        {field: 'name', headerName: t('Nombre Flujo')},
        {field: 'montoMinimo', headerName: t('Monto mínimo'), valueFormatter:currencyFormatter, ...rightAligned},
        {field: 'montoMaximo', headerName: t('Monto Máximo'), valueFormatter:currencyFormatter, ...rightAligned},
        {
            cellRenderer: DetailRowIconRender,
            headerComponent: GridAddButtonComponent,
            cellRendererParams: {editable},
            headerComponentParams: {editable},
            maxWidth: 75

        },
    ]);

    useEffect(() => {
        onFilter();
        // eslint-disable-next-line
    }, []);

    return(
        <Page>
            <AgGridContainer className="ag-theme-material" width={'100%'}>
                <AgGridReact
                    {...getAgGridDefault(false)}
                    rowData={data}
                    context={{...addEditGridContext}}
                    getRowId={(params) => params.data.id}
                    columnDefs={columnDefs}
                    pagination={true}
                />
            </AgGridContainer>
            <FlujoAutorizacionDetalle disabled={!editable} editForm={editForm}/>
        </Page>
    )

}

export default FlujoAutorizaciones;
