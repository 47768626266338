import Page from "../../../common/layout/Page";
import {AgGridReact} from "ag-grid-react";

import AgGridContainer from "../../../common/layout/AgGridContainer";
import useApi from "../../../api/useApi";
import {useEffect, useState} from "react";
import useGridFilter from "../../../common/hooks/useGridFilter";
import {useMenu} from "../../../common/layout/MenuProvider";
import menuOptions from "../../../common/security/menuOptions";

import GridButtonsComponent from "../../../common/components/agGrid/GridButtonsComponent";
import {useTranslation} from "react-i18next";

import {getAgGridDefault} from "../../../common/components/agGrid/agGridUtility";
import PerfilesDetalle from "./PerfilesDetalle";

import {ViewDeleteRowIconRenderer} from "../../../common/components/agGrid/ViewDeleteRowIconRenderer";
const Perfiles =()=>{
    const {t} = useTranslation()
    const api = useApi('security/perfiles')

    const {data, onFilter, addEditGridContext, editForm} = useGridFilter({api});
    const editable = useMenu().isEditable(menuOptions.perfiles);

    const [columnDefs, setColumnDefs] = useState([])

    useEffect(() => {
        setColumnDefs([
            {field: 'name', headerName: t('Perfil')},

            {
                field: 'edit', headerName: t('Ver'),
                cellRenderer: ViewDeleteRowIconRenderer,
                cellRendererParams: {editable},
                headerComponent: GridButtonsComponent,
                headerComponentParams: {editable},
                maxWidth: 150
            },
        ])

        onFilter();
// eslint-disable-next-line
    }, [editable, t]);
    return(
    <>
        <Page>
            <AgGridContainer className="ag-theme-material">
                <AgGridReact
                    {...getAgGridDefault(false)}
                    rowData={data}
                    context={addEditGridContext}
                    getRowId={(params) => params.data.id}
                    columnDefs={columnDefs}
                    pagination={true}
                />
            </AgGridContainer>
        </Page>
        <PerfilesDetalle editForm={editForm}></PerfilesDetalle>
    </>)
}

export default  Perfiles
