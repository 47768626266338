import {useTranslation} from "react-i18next";
import useApi from "../../../api/useApi";
import useGridFilter from "../../../common/hooks/useGridFilter";
import {useCallback, useRef, useState} from "react";
import {currencyFormatter, dateFormatter, enumFormatter} from "../../../common/formatters/useLocaleFormatters";
import {getAgGridDefault, rightAligned} from "../../../common/components/agGrid/agGridUtility";
import Page from "../../../common/layout/Page";
import FormFilter from "../../../common/components/forms/formFilter/FormFilter";
import Field from "../../../common/components/forms/Field";
import FieldTextBox from "../../../common/components/forms/fields/FieldTextBox";
import FieldDateRangePicker from "../../../common/components/forms/fields/dateRangePicker/FieldDateRangePicker";
import AgGridContainer from "../../../common/layout/AgGridContainer";
import {AgGridReact} from "ag-grid-react";
import FieldAutoComplete from "../../../common/components/forms/fields/FieldAutocomplete";
import {DetailRowIconRender} from "../../../common/components/agGrid/DetailRowIconRender";
import DetalleFactura from "./DetalleFactura";
import EstatusLayoutRender from "../ordenesCompra/EstatusLayoutRender";
import FieldMultiSelect from "../../../common/components/forms/fields/multiselect/FieldMultiSelect";
import useNotification from "../../../common/components/hooks/useNotification";
import {useAuth} from "../../../common/security/AuthProvider";
import {defaultFilterProcesoFacturacion, estatusProcesoFacturacion} from "../../Constantes";
import {procesoLog} from "../../Constantes";
import _ from "lodash";
import Button from "@mui/material/Button";
import {CheckCircleOutlined} from "@mui/icons-material";
import {Box} from "@mui/material";
import AutorizacionesDialog from "./AutorizacionesDialog";
import {ViewDeleteIconRenderer} from "../../../common/components/agGrid/ViewDeleteIconRenderer";
import {useMenu} from "../../../common/layout/MenuProvider";
import menuOptions from "../../../common/security/menuOptions";

const ProcesoFacturacion = ({nuevoTotal}) => {
    const {t} = useTranslation();
    const editable = true// isEditable(menuOptions.usuarios);
    const puedeEliminar = useMenu().hasAccess(menuOptions.eliminaOC);
    const gridRef = useRef();
    const notification = useNotification();
    const api = useApi('configuracion/recepciones');
    const {esProveedor} = useAuth();
    const onDataLoaded = (data) => {
        nuevoTotal(1,_.sumBy(data, 'monto'));
    }
    const {
        data,
        onFilter,
        addEditGridContext,
        editForm
    } = useGridFilter({api, onDataLoaded});

    const ActualizarTabla = () => {
        addEditGridContext.refresh();
    }

    const [columnDefs] = useState([
        {field: 'folioOrdenCompra', headerName: t('OC'), resizable: false, minWidth: 150, headerCheckboxSelection: true, checkboxSelection: true},
        {field: 'numeroRecepcion', headerName: t('ID Recepción'), resizable: false, minWidth: 150},
        {field: 'folioFactura', headerName: t('Folio Factura'), resizable: false, minWidth: 150},
        {field: 'proveedor', headerName: t('Proveedor'),resizable: false, minWidth: 500},
        {field: 'rfc', headerName: t('RFC'), resizable: false, minWidth: 150},
        {field: 'fechaProgramadaPago', headerName: t('Fecha Prog. Pago'), valueFormatter: dateFormatter,resizable: false, minWidth: 200},
        {field: 'monto', headerName: t('Monto'), valueFormatter:currencyFormatter,resizable: false, minWidth: 150, ...rightAligned},
        {field: 'moneda', headerName: t('Moneda'), valueFormatter: enumFormatter, enum:'moneda', minWidth: 110, maxWidth: 110, resizable: false},
        {
            cellRenderer: EstatusLayoutRender,
            headerName: t('Estatus'),
            cellRendererParams: {editable, 'enumerador' : 'estatusProcesoFacturacion'},
            headerComponentParams: {editable},
            resizable: false,
            minWidth: 200, maxWidth: 200,
            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                if (nodeA.data.estatus === nodeB.data.estatus) return 0;
                return (nodeA.data.estatus > nodeB.data.estatus) ? 1 : -1;
            }
        },
        {
            cellRenderer: DetailRowIconRender,
            cellRendererParams: {editable},
            headerComponentParams: {editable},
            resizable: false,
            minWidth: 75, maxWidth:75
        },
        {
            cellRenderer: ViewDeleteIconRenderer,
            cellRendererParams: {puedeEliminar, ActualizarTabla, 'proceso' : 'rec'},
            headerComponentParams: {puedeEliminar},
            minWidth: 75, maxWidth:75

        }
    ]);

    const onExport=(filtro)=>{
        addEditGridContext.onExport(filtro,'Proceso Facturación');
    }

    const closeAutorizacionesModal = async () => {
        setAutorizacionesDialogOpen(false);
        await addEditGridContext.refresh();
    }

    const [autorizacionesDialogOpen, setAutorizacionesDialogOpen] = useState(false);

    const [facturasAgrupadas, setFacturasAgrupadas] = useState([])

    const isRowSelectable = useCallback((rowNode) => {
        return rowNode.data ? rowNode.data.estatusId === estatusProcesoFacturacion.PorAutorizar : false;
    }, []);

    const autorizacionMasiva = async () => {
        if(gridRef.current.api.getSelectedRows().length === 0){
            notification.warning(t('Selecciona al menos un registro'));
            return;
        }
        setFacturasAgrupadas(gridRef.current.api.getSelectedRows()?.map(e => e.facturaId));
        setAutorizacionesDialogOpen(true);
    }

    return(
        <>
            <Box display="flex" justifyContent="flex-end" position={'absolute'} top={18} right={0}>
                <Button sx={{width: 250}} variant="outlined" color={'info'} startIcon={<CheckCircleOutlined/>}
                        onClick={ () => autorizacionMasiva() }>
                    {t('Autorizar')}
                </Button>
            </Box>
            <Page>
                <FormFilter defaultValue={defaultFilterProcesoFacturacion} onFilter={onFilter} onExport={onExport} filterOnInit={true} sessionSorageKey={'filtroProcesoFacturacion'}>
                    <Field label={'OC'} field={'folioOrdenCompra'} render={FieldTextBox} md={1}/>
                    <Field label={'ID Recepcion'} field={'numeroRecepcion'} render={FieldTextBox} md={1.3}/>
                    <Field label={'Folio Factura'} field={'folioFactura'} render={FieldTextBox} md={1.2}/>
                    <Field label={'Proveedor'} field={'proveedorId'} render={FieldAutoComplete} source={'proveedores'} md={1.3} visible={() => !esProveedor}/>
                    <Field label={'RFC'} field={'RFC'} render={FieldTextBox} md={1.3} visible={() => !esProveedor}/>
                    <Field label={'Fecha Prog. Pago'} field={'fechaProgramadaPago'} render={FieldDateRangePicker}   md={1.5} xl={3.2}/>
                    <Field label={'Estatus'} field={'estatusIds'} render={FieldMultiSelect} source={'estatusProcesoFacturacion'} md={2}/>
                </FormFilter>

                <AgGridContainer className="ag-theme-material" width={'100%'}>
                    <AgGridReact
                        {...getAgGridDefault(false)}
                        ref={gridRef}
                        rowData={data}
                        rowSelection={"multiple"}
                        context={addEditGridContext}
                        getRowId={(params) => params.data.id}
                        columnDefs={esProveedor ? columnDefs.filter((columna) => columna.field !== 'proveedor' && columna.field !== 'rfc') : columnDefs}
                        pagination={true}
                        isRowSelectable={isRowSelectable}
                    />
                </AgGridContainer>
                <DetalleFactura editarForma={editForm} proceso={procesoLog.Recepcion} />
                <AutorizacionesDialog open={autorizacionesDialogOpen} onClose={closeAutorizacionesModal} facturas={facturasAgrupadas}/>
            </Page>
        </>
    )
}

export default ProcesoFacturacion;
