import {useTranslation} from "react-i18next";
import {Box, IconButton} from "@mui/material";
import PropTypes from "prop-types";
import {DeleteForever} from "@mui/icons-material";
import useApi from "../../../api/useApi";
import useNotification from "../hooks/useNotification";

export const ViewDeleteIconRenderer=({ data, ActualizarTabla, proceso, puedeEliminar})=>{
    const { t } = useTranslation()
    const notification = useNotification()
    const api = useApi('configuracion/ordenesCompra');
    const apiRecepciones = useApi('configuracion/recepciones');

    const onDelete = async (id, proceso) => {
        if (await notification.confirm('notificaciones.confirmarEliminar')) {
            if(proceso === 'oc'){
                await api.delete(id)
            }else{
                await apiRecepciones.delete(id)
            }
            ActualizarTabla()
        }
    }
    return (
        <Box display={'flex'} justifyContent={'center'} width={'100%'} gap={'10px'}>
            {
                puedeEliminar &&
                <IconButton title={t('Eliminar')} onClick={()=> onDelete(data.id, proceso)} size={"small"} color="error">
                    <DeleteForever/>
                </IconButton>
            }
        </Box>
    )

}

ViewDeleteIconRenderer.propTypes = {
    data: PropTypes.any,
    context: PropTypes.any
}
