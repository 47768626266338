import DialogTitle from "@mui/material/DialogTitle";
import {Box, Button, IconButton, Step, StepLabel, Stepper} from "@mui/material";
import {
    CheckCircleOutlined,
    Close,
    HistoryOutlined,
    WebStoriesOutlined
} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import DialogContent from "@mui/material/DialogContent";
import useApiSource from "../../../api/useApiSource";
import {currencyFormat, dateFormat} from "../../../common/formatters/format";
import DialogActions from "@mui/material/DialogActions";
import DialogContentComponent from "../../../common/components/Shared/DialogContentComponent";
import DownloadOrUploadButtonIcon from "../../../common/components/Shared/DownloadOrUploadButtonIcon";
import Generales from "../../../common/components/Shared/Generales";
import FieldCalendar from "../../../common/components/forms/fields/FieldCalendar";
import {useAuth} from "../../../common/security/AuthProvider";
import useApi from "../../../api/useApi";
import {
    estatusAdminEnOrdenProcesoFacturacion,
    estatusProcesoFacturacion

} from "../../Constantes";
import LogOrdenCompra from "../ordenesCompra/LogOrdenCompra";
import useGridFilter from "../../../common/hooks/useGridFilter";
import OrdenCompraDetalle from "../ordenesCompra/OrdenCompraDetalle";
import ComentariosRechazoFactura from "./ComentariosRechazoFactura";
import useNotification from "../../../common/components/hooks/useNotification";
import AutorizadoresEnCurso from "./AutorizadoresEnCurso";
import {
    getFirstDayOfMonthIsoString,
    getTodayIsoString
} from "../../../common/utility/dateUtility";
import DownloadOrUploadButton from "../../../common/components/Shared/DownloadOrUploadButton";

const DetalleFactura = ({editarForma, proceso}) => {
    const {t} = useTranslation()
    const editable = true// isEditable(menuOptions.usuarios);
    const open = editarForma.id != null;
    const notification = useNotification();
    const api = useApi('configuracion/recepciones');
    const apiAutorizacion = useApi('configuracion/flujoFacturacion');
    const [data, setData] = useState([]);
    const apiSource = useApiSource();
    const {esProveedor} = useAuth()
    const [logDialogOpen, setLogDialogOpen] = useState(false);
    const [comentariosDialogOpen, setComentariosDialogOpen] = useState(false);
    const [autorizadoresDialogOpen, setAutorizadoresDialogOpen] = useState(false);
    const {addEditGridContext, editForm} = useGridFilter({api});
    const [cargandoFactura, setCargandoFactura] = useState(false);
    const fechaActual = getTodayIsoString();
    const primerDiaMes = getFirstDayOfMonthIsoString();
    const [xml, setXml] = useState(null);
    const [pdf, setPdf] = useState(null);

    useEffect(() => {
        if(open){
            load(editarForma.id).then()}
        // eslint-disable-next-line
    }, [editForm.id, open]);

    const load = async (id) => {
        if (id === 0 || id == null) {
            return
        }
        const response = await api.get(id);
        response['estados'] = await apiSource.get('estatusProcesoFacturacion');
        setData(response);
    }

    const changeDate = (value, field) => {
        data['fechaRealPago'] = value;
    }

    const changeDateRegistro = (value, field) => {
        data['fechaRegistroFactura'] = value;
    }

    const closeLogModal = () => {
        setLogDialogOpen(false);
    }

    const closeAutorizadoresModal = () => {
        setAutorizadoresDialogOpen(false);
    }

    const closeComentariosModal = () => {
        setComentariosDialogOpen(false);
        load(editarForma.id);
    }

    const onCargarFactura = async (value, field)=>{
        const formData = new FormData();
        formData.append('factura', value);
        setCargandoFactura(true);
        try {
            await api.post(`${editarForma.id}/factura`, formData);
        }catch (e){
            //deja catch vacío porque no se debe detener la ejecucion de la funcion
        }
        setCargandoFactura(false);

        load(editarForma.id).then()
    }

    const setXmlProv = (value,field) =>{
        setXml(value);
    }

    const setPdfProv = (value,field) =>{
        setPdf(value);
    }

    const onCargarFacturaProv = async ()=>{
        if(xml === null || pdf === null){
            notification.warning(t('Favor de seleccionar la factura en Xml y Pdf'));
            return
        }
        const formData = new FormData();
        formData.append('factura', xml);
        formData.append('facturaPdf', pdf);
        setCargandoFactura(true);
        try {
            await api.post(`${editarForma.id}/factura`, formData);
        }catch (e){
            //deja catch vacío porque no se debe detener la ejecucion de la funcion
        }
        setXml(null);
        setPdf(null);
        setCargandoFactura(false);

        load(editarForma.id).then()
    }

    const rechazaOAutoriza = async (esAutorizacion, comentarios) => {

        let accion = (esAutorizacion) ? t('autorizar') : t('rechazar');
        let mensaje = t('¿Está seguro que desea ') + accion;


        if (!esAutorizacion ||  (esAutorizacion && await notification.confirm(mensaje))) {
            const FacturaId = data['facturaId'];
            const fechaRealPago = esAutorizacion ? data['fechaRealPago'] : new Date().toJSON();
            if (esAutorizacion && isNaN(Date.parse(data['fechaRealPago']))) {
                notification.warning(t('Seleccione una fecha correcta'));
                return;
            }
            const fechaRegistroFactura = data['fechaRegistroFactura'];

            setTimeout(async () => {
                await apiAutorizacion.post('apruebaRechazaFlujo', {
                    FacturaId,
                    esAutorizacion,
                    comentarios,
                    fechaRealPago,
                    fechaRegistroFactura
                }, {showLoading: true});

                await load(editarForma.id);
            },500)
        }
    }



    return(
        <>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'md'}>
                <DialogTitle marginInline={4} marginTop={2} fontWeight={'bold'}>
                    <Box sx={{display: "grid", gridAutoColumns: "1fr", width: "100%", gap: "10px"}}>
                        <Box sx={{gridRow: "1", gridColumn: "1/9"}}>
                            {esProveedor ? t(`Detalle de Factura`) : t(`Autorización`)}
                        </Box>

                        {esProveedor ?
                            <Box  sx={{gridRow: "1", gridColumn: "8/10"}}>
                                <Button size={'small'}  variant="outlined" color={'info'} onClick={() => addEditGridContext.onEdit(data?.ordenCompraId)}>
                                    {t('DETALLE ')}{data?.folioOrdenCompra}
                                </Button>
                            </Box>
                            :
                            <></>
                        }
                    </Box>

                    <IconButton sx={{position: 'absolute', right: 8, top: 8}} onClick={editarForma.onCancel}>
                        <Close/>
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{marginInline: 4}}>
                    <Box sx={{width: "100%"}} marginBottom={3}>
                        {!esProveedor &&
                            <Stepper activeStep={data?.estatusId >= 0 ? estatusAdminEnOrdenProcesoFacturacion[data?.estatusId] : data?.estatusId} alternativeLabel>
                                {data?.estados?.sort((a, b) => a.id - b.id).map((label) => (
                                    <Step key={label.name}>
                                        <StepLabel>{label.name}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        }
                    </Box>
                    <DialogContentComponent>
                        {esProveedor ?
                            <>
                                <Generales>
                                    <Box display={'grid'} sx={{gridTemplateColumns: "2fr 2fr 2fr", marginBottom:'10px'}}>
                                        <label className={'title'}>{t('EMPRESA')}</label>
                                        <label className={'title'}>{t('DÍAS DE CRÉDITO')}</label>
                                        <label className={'title'}>{t('FACTURA')}</label>
                                        <label className={'label'}>{data?.folioFactura}</label>
                                        <label className={'label'}>{data?.diasCredito}</label>
                                        <Box sx={{display: "grid", gridAutoColumns: "1fr", width: "100%", gap: "10px"}}>
                                            <Box color={'black'} fontWeight={'bold'} sx={{gridRow: "1", gridColumn: "1/2"}}>
                                                {!cargandoFactura && <DownloadOrUploadButton
                                                    value={data?.rutaXml}
                                                    id={'xml'} text={'XML'} accepted={'.xml'} allowedFileTypes={['text/xml']}
                                                                                                 soloDescarga={!(data.estatusId === estatusProcesoFacturacion.PendienteCargaFactura)}
                                                                                                 onChange={setXmlProv} field={data?.rutaXml}/>
                                                }
                                            </Box>
                                            <Box color={'black'} fontWeight={'bold'} textAlign={'right'} sx={{gridRow: "1", gridColumn: "2/3"}}>
                                                {!cargandoFactura && <DownloadOrUploadButton value={data?.rutaPdf}
                                                                                             id={'pdf'} text={'PDF'} accepted={'.pdf'} allowedFileTypes={['application/pdf']}
                                                                                             soloDescarga={!(data.estatusId === estatusProcesoFacturacion.PendienteCargaFactura)}
                                                                                             onChange={setPdfProv} field={data?.rutaPdf}/>
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row-reverse', fontWeight: 'bold', fontSize:'larger', paddingBlock:'20px', color:'primary.main'}}>
                                        {t('Monto Total a Pagar  ')}{currencyFormat(data?.monto)}
                                    </Box>

                                </Generales>
                            </>
                            :
                            <>
                                <Generales>
                                    <Box display={'grid'} sx={{gridTemplateColumns: "50% 50%", gap: '5px'}}>
                                        <Box display={'inline'}>
                                            <label style={{marginRight: '5px'}} className={'title'}>{t('FACTURA')}</label>
                                            {!cargandoFactura && <DownloadOrUploadButtonIcon value={data?.rutaXml} valuePdf={data?.rutaPdf}
                                                                         soloDescarga={!(data.estatusId === estatusProcesoFacturacion.PendienteCargaFactura)}
                                                                         onChange={onCargarFactura} field={data?.rutaXml}/>
                                            }
                                        </Box>
                                        <Box textAlign={'right'}>
                                            <Box display={'grid'} sx={{gridTemplateColumns: "34% 33% 33%", gap: '2px'}}>
                                                <Box display={'inline'}>
                                                    {
                                                        data?.esFacturaMesAnterior ?
                                                            <>
                                                                <label
                                                                   className={'title'} style={{ fontSize: 12, paddingRight: 2}}>{t('FECHA DE REGISTRO')}</label>
                                                            </>
                                                            :
                                                            <>
                                                                <label></label>
                                                            </>
                                                    }
                                                </Box>
                                                <Box display={'inline'}>
                                                    {
                                                        data?.esFacturaMesAnterior ?
                                                            <>
                                                                <FieldCalendar className={"compacto"} value={data?.fechaRegistroFactura} field={data?.fechaRegistroFactura} minDate={primerDiaMes}
                                                                               onChange={changeDateRegistro}/>
                                                            </>
                                                            :
                                                            <>
                                                                <label></label>
                                                            </>
                                                    }
                                                </Box>
                                                <Box textAlign={'right'}>
                                                    <Button size={'small'} variant="outlined" color={'info'}
                                                            onClick={() => addEditGridContext.onEdit(data?.ordenCompraId)}>
                                                        {t('DETALLE ')}{data?.folioOrdenCompra}
                                                    </Button>
                                                </Box>
                                            </Box>

                                        </Box>
                                        <label className={'label'}>{data?.folioFactura}</label>
                                        <label></label>
                                        <label className={'title'}>{t('PROVEEDOR')}</label>
                                        <label></label>
                                        <label className={'label'}>{data?.proveedor}</label>
                                        <label></label>
                                        <label className={'title'}>{t('DÍAS DE CRÉDITO')}</label>
                                        <Box textAlign={'right'}>
                                            <Box color={'primary.main'}
                                                 fontWeight={'bold'}>{t('Monto ')} {currencyFormat(data?.monto)}</Box>
                                        </Box>
                                        <label className={'label'}>{data?.diasCredito}</label>
                                        <label></label>
                                        <label className={'title'}>{t('FECHA ESTIMADA DE PAGO')}</label>
                                        <Box display={'grid'} sx={{gridTemplateColumns: "35% 65%", gap: '2px'}}>
                                            <Box display={'inline'}>
                                                <label></label>
                                            </Box>
                                            <Box textAlign={'inline'}>
                                                <label className={'title'}>{t('FECHA REAL DE PAGO')}</label>
                                            </Box>
                                        </Box>
                                        <label className={'label'}>{dateFormat(data?.fechaEstimadaPago)}</label>
                                        <Box display={'grid'} sx={{gridTemplateColumns: "20% 80%", gap: '2px'}}>
                                            <Box display={'inline'}>
                                                <label></label>
                                            </Box>
                                            <Box textAlign={'right'}>
                                                <FieldCalendar value={data?.fechaRealPago} field={data?.fechaRealPago} minDate={fechaActual}
                                                               onChange={changeDate} disabled={(!(data?.habilitaFechaRealPago && (data?.estatusId === estatusProcesoFacturacion.ProgramacionPago || data?.estatusId === estatusProcesoFacturacion.PorAutorizar)))}/>
                                            </Box>
                                        </Box>

                                    </Box>


                                </Generales>
                            </>
                        }

                    </DialogContentComponent>
                    <DialogActions>
                        <Button sx={{color: 'text.primary'}} onClick={() => setLogDialogOpen(true)}>
                            <HistoryOutlined /> <label style={{fontWeight:'bold'}}> LOG </label>
                        </Button>
                        <Box sx={{display: "grid", gridAutoColumns: "1fr", width: "100%", gap: "10px"}}>
                            {esProveedor ?
                                <>
                                    <Box color={'black'} fontWeight={'bold'} sx={{gridRow: "1", gridColumn: "8/9"}} >
                                        <Button  variant="outlined" color={'inherit'} startIcon={<Close/>}
                                                 disabled={!(data.estatusId === estatusProcesoFacturacion.PendienteCargaFactura)}
                                                 onClick={editarForma.onCancel}>
                                            {t('CACELAR')}
                                        </Button>
                                    </Box>
                                    <Box textAlign={'right'} color={'black'} fontWeight={'bold'}
                                         sx={{gridRow: "1", gridColumn: "9/10"}}>
                                        <Button  variant="outlined" color={'success'} startIcon={<CheckCircleOutlined/>}
                                                 disabled={!(data.estatusId === estatusProcesoFacturacion.PendienteCargaFactura)}
                                            onClick={() => onCargarFacturaProv()}
                                                 >
                                            {t('GUARDAR')}
                                        </Button>
                                    </Box>
                                </>
                                :
                                <>
                                    <Box textAlign={'right'} color={'black'} fontWeight={'bold'}
                                         sx={{gridRow: "1", gridColumn: "3/8"}}>
                                        <Button  variant="outlined" color={'info'} startIcon={<WebStoriesOutlined/>}  onClick={() => setAutorizadoresDialogOpen(true)}
                                                 >
                                            {t('AUTORIZADORES EN CURSO')}
                                        </Button>
                                    </Box>
                                    <Box color={'black'} fontWeight={'bold'} sx={{gridRow: "1", gridColumn: "8/9"}} >
                                        <Button  variant="outlined" color={'error'} startIcon={<Close/>} disabled={(!(data?.esAutorizador && (data?.estatusId === estatusProcesoFacturacion.ProgramacionPago || data?.estatusId === estatusProcesoFacturacion.PorAutorizar)))}
                                                 onClick={() => setComentariosDialogOpen(true)}>
                                            {t('RECHAZAR')}
                                        </Button>
                                    </Box>
                                    <Box textAlign={'right'} color={'black'} fontWeight={'bold'}
                                         sx={{gridRow: "1", gridColumn: "9/10"}}>
                                        <Button  variant="outlined" color={'success'} startIcon={<CheckCircleOutlined/>} disabled={(!(data?.esAutorizador && (data?.estatusId === estatusProcesoFacturacion.ProgramacionPago || data?.estatusId === estatusProcesoFacturacion.PorAutorizar)))}
                                        onClick={() => rechazaOAutoriza(true,'')}>
                                            {t('AUTORIZAR')}
                                        </Button>
                                    </Box>
                                </>
                            }
                        </Box>
                    </DialogActions>
                </DialogContent>

            </Dialog>
            <OrdenCompraDetalle editForm={editForm} disabled={!editable}/>
            <LogOrdenCompra open={logDialogOpen} id={editarForma.id} procesoId={proceso} onClose={() => closeLogModal()}/>
            <ComentariosRechazoFactura open={comentariosDialogOpen} onClose={() => closeComentariosModal()}  onRechazar={rechazaOAutoriza}/>
            <AutorizadoresEnCurso open={autorizadoresDialogOpen} id={editarForma.id} onClose={() => closeAutorizadoresModal()}/>
        </>
    )
}

export default DetalleFactura;
