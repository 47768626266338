/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from 'react'
import useNotification from "../components/hooks/useNotification";

const useGridFilter = ({ api, onDataLoaded, loadOnInit}) => {
    const [id, setId] = React.useState(null)
    const [data, setData] = React.useState([])
    const notification = useNotification()
    const [lastFilter, setLastFilter] = useState({})



    const onFilter = useCallback(async (filter, showLoading) => {
        const data = await api.filter(filter,{showLoading:showLoading ?? true})
        setLastFilter(filter)
        setData(data)
        if (onDataLoaded != null) { onDataLoaded(data, filter) }
    },[])

    useEffect(() => {
        if (loadOnInit)
            onFilter({}, true).then()
    }, [loadOnInit, onFilter]);

    const onAddData = useCallback((data) => {
        setData(data)
    },[])

    const onSaved =useCallback( async (filter) => {
        setId(null)
        await refresh()
    },[lastFilter])

    const onExport =useCallback(  (filter, nombreArchivo) => {
        api.excel(filter, nombreArchivo)
    },[])

    const onDelete = async (id, filter) => {
        if (await notification.confirm('notificaciones.confirmarEliminar')) {
            await api.delete(id)
            await onFilter(filter)
        }
    }

    const onCancel =()=>{
        setId(null);
    }

    const refresh = useCallback(async (showLoading)=>{
        await onFilter(lastFilter, showLoading)
    },[lastFilter, onFilter])

    return {
        data,
        onAddData,
        onFilter,
        lastFilter,
        refresh,
        setData,
        editForm: {
            id,
            onSaved,
            onCancel
        },
        addEditGridContext:
            {
                onEdit: (id) => setId(id),
                onDelete,
                onAdd: () =>
                    setId(0),
                onExport,
                refresh
            }
    }
}

export default useGridFilter
