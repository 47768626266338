import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Box,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {Close} from "@mui/icons-material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentComponent from "../../common/components/Shared/DialogContentComponent";
import useApi from "../../api/useApi";
import Row from "./Row";
import {currencyFormat} from "../../common/formatters/format";
import useNotification from "../../common/components/hooks/useNotification";
const ArchivoDispersionDialog = ({open, onClose, facturas}) => {
    const {t} = useTranslation();
    const api = useApi('configuracion/facturas');
    const notification = useNotification();
    const[data, setData] = useState([facturas]);

    useEffect(() => {
        if(open){
            load()}
        // eslint-disable-next-line
    }, [open]);

    const load = async () => {
        if(facturas.length === 0){
            notification.warning(t('Selecciona al menos un registro'));
            return;
        }
        const response = await api.get('agruparFacturas',{'facturaIds':facturas})
        setData(response);
    }

    const cerrar = () => {
        onClose();
    }

    const desagruparFactura = (consecutivo, row) => {
        const newData = data;
        //se obtiene los index necesarios
        const indexPadre = data.findIndex(i => i.consecutivo === consecutivo);
        const maxConsecutivo = Math.max(...data?.map(e => e.consecutivo));
        const indexFacturaAgrupada = data[indexPadre].agrupados?.findIndex(a => a.id === row.id);

        // se agrega un consecutivo a la fila a desagrupar
        row['consecutivo'] = maxConsecutivo +1;
        row['agrupados'] = [];

        //se desagrupa la factura
        newData[indexPadre]?.agrupados?.splice(indexFacturaAgrupada,1);

        //se resta el monto total de la agrupacion de facturas
        newData[indexPadre].monto = (newData[indexPadre]?.monto - row.monto);

        //se agrega la nueva factura desagrupada
        newData?.splice(maxConsecutivo + 1,0, row);

        //verificamos si el grupo de agrupadas esta vacio o no
        //si esta vacio eliminamos el grupo de facturas agrupadas
        if(newData[indexPadre]?.agrupados?.length === 0){
            newData?.splice(indexPadre,1);
        }

        //se guarda el nuevo arreglo
        setData([...newData]);
    }

    const eliminarFactura = (esPadre, consecutivo,row) => {
        const newData = data;
        const indexPadre = data.findIndex(i => i.consecutivo === consecutivo);
        const indexFacturaAgrupada = esPadre ? data[indexPadre].agrupados?.findIndex(a => a.id === row.id) : 0;

        if(esPadre){
            newData?.splice(indexPadre,1);
        }else{
            newData[indexPadre]?.agrupados?.splice(indexFacturaAgrupada,1);
            //se resta el monto total de la agrupacion de facturas
            newData[indexPadre].monto = (newData[indexPadre]?.monto - row.monto);
            //verificamos si el grupo de agrupadas esta vacio o no
            //si esta vacio eliminamos el grupo de facturas agrupadas
            if(newData[indexPadre]?.agrupados?.length === 0){
                newData?.splice(indexPadre,1);
            }
        }

        //se guarda el nuevo arreglo
        setData([...newData]);
    }

    const generarArchivo = async() =>{
        let facturasString = '';
        data?.filter((row) => row.agrupados.length === 0 ).forEach((index, row) => facturasString += `${index.id}-0|`);
        data?.map((row, index)=> row.agrupados.map(f => facturasString += `${f.id}-${index+1}|`));
        facturasString = facturasString.slice(0,-1);
        if(facturasString === ''){
            notification.warning(t('Favor de seleccionar facturas'));
            return;
        }

        let response = await api.post('actualizarDispersion', {'facturas':facturasString},'archivoDispersion','txt');

        let mensajeMostrar = '';
        let idsError = [];
        let idsFacturas = [];
        let facturasNuevas = '';
        let agrupadasError = 0;

        if(response.length > 0){
            response.forEach((result) => {
                let elements = result.split('-');
                mensajeMostrar += elements[0] + '\n';
                agrupadasError = (agrupadasError === 1 ? 1 : (elements[1] === "0" ? 1 : 0));
                if(elements[1] !== "0"){
                    idsError.push(elements[1]);
                }
            });

            idsFacturas = facturasString.split('|');

            idsFacturas.forEach((element) => {
                let elem = element.split('-');
                let found = idsError.filter((id) => id === elem[0]);
                if(found.length === 0 && ((elem[1] === "0") || (elem[1] === "1" && agrupadasError === 0))){
                    facturasNuevas += element + '|';
                }
            });
            if(facturasNuevas.length > 0){
                facturasNuevas = facturasNuevas.slice(0,-1);
            }
        }

        if(mensajeMostrar.length > 1){
            notification.alterWarning(t(mensajeMostrar));
        }

        facturasString = response.length > 0 ? facturasNuevas : facturasString;

        if(facturasString.length > 0){
            await api.download('archivoDispersion', {facturasString},'archivoDispersion','zip');
        }
        cerrar();
    }

    return(
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={'lg'}>
            <DialogTitle marginInline={4} marginTop={2} fontWeight={'bold'}>
                {t('Resúmen de Archivo de Dispersión')}
                <IconButton sx={{position: 'absolute', right: 8, top: 8}} onClick={() => cerrar()}>
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{marginInline: 4}}>
                <DialogContentComponent>
                    <Box sx={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', fontSize:'larger', paddingBlock:'20px'}}>
                        {t('Facturas a Pagar')}
                    </Box>
                    <TableContainer sx={{height:400 }}>
                        <Table aria-label="sticky collapsible table" stickyHeader size={'small'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{width: 50}}/>
                                    <TableCell align="left" sx={{fontWeight:'bold', width: 50}}>{t('Factura')}</TableCell>
                                    <TableCell align="left" sx={{fontWeight:'bold', width: 250}}>{t('Proveedor')}</TableCell>
                                    <TableCell align="left" sx={{fontWeight:'bold', width: 150}}>{t('RFC')}</TableCell>
                                    <TableCell align="left" sx={{fontWeight:'bold', width: 100}}>{t('Fecha Prog. Pago')}</TableCell>
                                    <TableCell align="left" sx={{fontWeight:'bold', width: 50}}>{t('Moneda')}</TableCell>
                                    <TableCell align="right" sx={{fontWeight:'bold', width: 150}}>{t('Monto')}</TableCell>
                                    <TableCell sx={{width: 50}}/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.map((row, index) => (
                                    // <Row key={row.consecutivo + row.id} props={row} desagrupar={desagruparFactura} eliminar={eliminarFactura}/>
                                    <Row key={index} props={row} desagrupar={desagruparFactura} eliminar={eliminarFactura}/>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ display: 'flex', flexDirection: 'row-reverse', fontWeight: 'bold', fontSize:'larger', paddingInline:'75px', paddingBlock:'20px', color:'primary.main'}}>
                        {t('Monto Total a Pagar  ')}{currencyFormat(data?.reduce((a, b) => a + (b['monto'] || 0),0))}
                    </Box>
                </DialogContentComponent>
                <DialogActions>
                    <Box sx={{display: "grid", gridAutoColumns: "1fr", width: "100%", gap: "10px"}}>
                        <Box textAlign={'right'} color={'black'} fontWeight={'bold'}
                             sx={{gridRow: "1", gridColumn: "7/8"}}>
                            <Button  variant="outlined" color={'inherit'} onClick={() => cerrar()}>
                                {t('CANCELAR')}
                            </Button>
                        </Box>
                        <Box textAlign={'right'} color={'black'} fontWeight={'bold'} sx={{gridRow: "1", gridColumn: "8/10"}} >
                            <Button  variant="outlined" color={'info'} onClick={() => generarArchivo()}>
                                {t('Generar Archivo Dispersión')}
                            </Button>
                        </Box>
                    </Box>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default ArchivoDispersionDialog;
